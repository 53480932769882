<template>
  <div class="doctor-detail-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>医生</Breadcrumb-item>
        <Breadcrumb-item>促进记录</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="card-container">
        <Card>
          <p slot="title">基本信息</p>
          <div class="card-box clearfix">
            <Row>
              <Col :lg="{span: 2}" :md="{span: 4}" class="tac">
                <div class="card-avatar">
                  <div class="card-img">
                    <img :src="avater" alt="" width="80px" height="80px">
                  </div>
                  <p class="card-text">头像</p>
                </div>
              </Col>
              <Col :lg="{span: 22}" :md="{span: 20}">
                <div class="card-info">
                  <Form :label-width="85">
                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>身份证号：{{id_card}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>姓名：{{name}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>性别：{{sex | sex}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>民族：{{nation}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>生日：{{birthday}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>年龄：{{age}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>建档日期：{{create_time}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>服务员工：{{staff}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>联系电话：{{phone}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>方案名称：<a :href="plan_url" target="_blank" class="clicka">{{plan_name}}</a></p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>方案状态：{{plan_status_string}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>医生：{{booster}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p><a :href="'/#/base/member/detail/' + this.memberId + '/4'" target="_blank" class="clicka">查看药物记录</a></p>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div class="commom-panel-one">
        <Form class="search-form" :label-width="111">
          <Row>
            <Col span="5">
              <Form-item label="上次促进时间：">
                {{last_time}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="沟通时长：">
                {{continued_time}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="下次促进时间：">
                {{next_time}}
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="客户反馈：">
                {{feedback}}
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="本次服务结果：">
                {{result}}
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="后期跟进重点：">
                {{point}}
              </Form-item>
            </Col>
          </Row>
        </Form>
      </div>
      <div class="submit-box tac">
        <Button type="info" size="large" @click="goBack()">返回</Button>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import doctorService from '@/services/doctorService';
import { sex } from '@/filters/filters';
export default {
	components: {
		'v-title': vTitle,
	},
	filters: {
		sex,
	},
	data() {
		return {
			avater: '',
			id_card: '',
			name: '',
			sex: '',
			nation: '',
			birthday: '',
			age: '',
			create_time: '',
			staff: '',
			phone: '',
			plan_name: '',
			plan_status_string: '',
			plan_url: '',
			booster: '',
			last_time: '',
			continued_time: '',
			next_time: '',
			feedback: '',
			result: '',
			point: '',
			memberId: '',
		};
	},
	computed: {
		boostId() {
			return this.$route.params.boost_id;
		},
	},
	created() {
		var param = { boost_id: this.boostId };
		doctorService.getRecordDetail(param).then((data) => {
			this.avater = data.head_info.user_info.avater;
			this.id_card = data.head_info.user_info.id_card;
			this.name = data.head_info.user_info.name;
			this.sex = data.head_info.user_info.sex;
			this.nation = data.head_info.user_info.nation;
			this.birthday = data.head_info.user_info.birthday;
			this.age = data.head_info.user_info.age;
			this.create_time = data.head_info.user_info.create_time;
			this.staff = data.head_info.user_info.staff;
			this.phone = data.head_info.user_info.phone;
			this.plan_name = data.head_info.plan.plan_name;
			this.plan_status_string = data.head_info.plan.plan_status_string;
			this.plan_url = data.head_info.plan.plan_url;
			this.booster = data.head_info.user_info.booster;
			this.last_boost_id = data.last_boost_id;
			this.last_time = data.last_time;
			this.continued_time = data.continued_time;
			this.next_time = data.next_time;
			this.feedback = data.feedback;
			this.result = data.result;
			this.point = data.point;
			this.memberId = data.head_info.user_info.member_id;
		});
	},
	methods: {
		goBack() {
			this.$router.push({ name: 'doctorList', params: { member_id: this.memberId } });
		},
	},
};
</script>

<style lang="css" scoped>
.doctor-detail-container .card-container .ivu-card-head > p {
  color: #fff;
}
.doctor-detail-container .card-container .card-box {
  position: relative;
}
.doctor-detail-container .card-container .ivu-col {
  margin-bottom: 20px;
}
.doctor-detail-container .card-container .card-avatar {
  padding: 30px 0;
}
.doctor-detail-container .card-container .card-img img {
  border-radius: 100%;
}
.doctor-detail-container .card-container .card-text {
  text-align: center;
  padding-top: 8px;
}
.doctor-detail-container .card-container .card-info {
  padding-top: 30px;
}
.doctor-detail-container .card-container .ivu-form-item {
  margin-bottom: 0;
}
.doctor-detail-container .clicka {
  color: #ff8400;
  text-decoration: underline;
}
.doctor-detail-container .submit-box {
  margin-top: 30px;
}
.doctor-detail-container .ivu-btn-success,
.doctor-detail-container .ivu-btn-info {
  padding: 11px 48px;
  margin: 0 26px;
}
.doctor-detail-container .add-problem {
  padding: 10px 30px;
}
.doctor-detail-container .add-problem .add-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  background: url(@/assets/img/icon_add.png) no-repeat left;
}
.doctor-detail-container .ivu-radio-wrapper {
  margin-right: 30px;
}
</style>
